import styled from 'styled-components';
import { down } from 'styled-breakpoints';

export const Wrapper = styled.div`
  width: 100%;
  border-radius: 8px;
  border: 1px solid #eeeef1;
  background: rgba(8, 51, 68, 0.05);
`;

export const Header = styled.div`
  appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  min-height: 72px;

  &:hover {
    cursor: pointer;
  }
`;

export const IconWrapper = styled.div<{ isExpanded?: boolean }>`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    color: ${({ theme }) => theme.brand.blue};
    transform: rotate(${({ isExpanded }) => (isExpanded ? '180deg' : '0deg')});
  }
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.brand.blue};
`;

export const HeaderText = styled.div`
  margin: 26px 0;
  flex: 1;
`;

export const QuestionText = styled.span`
  color: #083344;
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 130%;

  ${down('md')} {
    font-size: 18px;
  }
`;

export const Content = styled.div``;

export const TextContentWrapper = styled.div`
  padding: 0 24px 24px;

  ${Text} {
    color: #083344;
    font-family: Inter;
    font-size: 40px;
    font-weight: 400;
    line-height: 32px;

    ${down('md')} {
      font-size: 16px;
      line-height: 25px;
    }
  }
`;
